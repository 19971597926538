import React, { ReactElement, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@objects/button'

import {
  materialShopAddItem,
  $materialShopCart,
  materialShopRemoveItem,
  IMaterialShopCartItem,
} from '@services/stores/materialShop'
import { useStore } from '@nanostores/react'
import Image from '@objects/image'
import Headline from '@objects/headline'
import { Checkbox } from '@objects/formfields'
import { Typography } from '@material-ui/core'
import MaterialShopCartItem from '../materialShopCartItem'
import Container from '@objects/container'
import Copy from '@components/copy'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 0,
  },
  footer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(24),
  },
  footerItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonSubmit: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },
  buttonCancel: {
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
    },
  },
  cartItem: {
    marginTop: theme.spacing(8),
    borderBottom: '1px solid #000',
  },
  heading: {
    ...theme.typography.infotext,
    fontWeight: 700,
    color: theme.palette.common.black,
    textAlign: 'left',
    width: '70%',
  },
  includedItems: {
    margin: theme.spacing(6, 0),
    padding: theme.spacing(6, 7),
    background: theme.palette.common.white,
  },
  includedItemsHeadline: {
    fontWeight: 700,
  },
  includedItem: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    padding: theme.spacing(4),
    '& > label': { marginRight: 0 },
    '&:not(:last-child)::after': {
      position: 'absolute',
      content: '""',
      borderBottom: '1px solid rgba(0, 0, 0, 0.20)',
      width: '100%',
      bottom: '0',
      left: '0',
    },
  },
  includedItemImage: {
    margin: theme.spacing(0, 8),
    width: theme.spacing(15.5),
    border: '1px solid rgba(0, 0, 0, 0.20)',
    background: '#808080',
  },
}))

type MaterialShopCartProps = {
  subline: RvG.Contentful.BasicRichTextType
}

export default function MaterialShopCart({
  subline,
}: MaterialShopCartProps): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const materialShopCart = useStore($materialShopCart)

  const topItems = materialShopCart.items.filter(
    (entry) => entry.category.identifier !== 'pressSocial'
  )
  const bottomItems = materialShopCart.items.filter(
    (entry) => entry.orderInCart
  )

  return (
    <div id="cart">
      <Container
        width={'sm'}
        background={'light'}
        className={classes.container}
      >
        <Grid container spacing={5}>
          {topItems.map((item: IMaterialShopCartItem) => (
            <MaterialShopCartItem item={item} key={item.id} />
          ))}
          {materialShopCart.orderInCartItems.length > 0 && (
            <Grid item xs={12} className={classes.includedItems}>
              <Headline level={4} className={classes.includedItemsHeadline}>
                {intl.formatMessage({
                  id: 'materialshop.cart.includeditems.headline',
                })}
              </Headline>
              <Copy richtext={subline} />
              {materialShopCart.orderInCartItems.map((orderInCartItem) => {
                const isSelected = bottomItems.some(
                  (item) => item.id === orderInCartItem.id
                )

                return (
                  <Grid
                    item
                    xs={12}
                    className={classes.includedItem}
                    key={orderInCartItem.id}
                  >
                    <div
                      onClick={() => {
                        if (!isSelected) {
                          materialShopAddItem(orderInCartItem, {
                            sections: [],
                          })
                        }
                        if (isSelected) {
                          materialShopRemoveItem(orderInCartItem)
                        }
                      }}
                    >
                      <Checkbox
                        name={orderInCartItem.id}
                        checked={isSelected}
                      />
                    </div>
                    <Image
                      image={orderInCartItem.images[0].fluid}
                      className={classes.includedItemImage}
                    />
                    <Typography className={classes.heading}>
                      {intl.formatMessage(
                        {
                          id: 'materialshop.cart.includeditems.package',
                        },
                        { name: orderInCartItem.name }
                      )}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Grid>
      </Container>
      <Container width={'sm'} className={classes.footer}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} className={classes.footerItem}>
            <Button
              className={classes.buttonCancel}
              to={materialShopCart.pathToShop}
              formSubmit
              icon="Add"
              iconPosition={'right'}
              light
              hideLinkIcon
            >
              {intl.formatMessage({
                id: 'materialshop.cart.button.basket',
              })}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} className={classes.footerItem}>
            <Button
              className={classes.buttonSubmit}
              to={materialShopCart.pathToCheckout}
              disabled={materialShopCart.items.length === 0}
              icon="ChevronRight"
              iconPosition={'right'}
              hideLinkIcon
            >
              {intl.formatMessage({ id: 'materialshop.form.checkoutHeadline' })}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
